import { lazy } from "react";
import { Outlet } from "react-router-dom";

import Loadable from "../components/loader/Loadable";

const VoiceCallPage = Loadable(lazy(() => import("../pages/public/Apply/VoiceCall")));
const ApplicationPage = Loadable(lazy(() => import("../pages/public/Apply/Application")));

const CompanyRedirectPage = Loadable(lazy(() => import("../pages/public/AdminRedirect/CompanyRedirect")));
const CandidateRedirectPage = Loadable(lazy(() => import("../pages/public/AdminRedirect/CandidateRedirect")));

const AumaBookerPage = Loadable(lazy(() => import("../pages/public/Booker/AumaBooker")));
const UpcomingBookerPage = Loadable(lazy(() => import("../pages/public/Booker/UpcomingBooker")));
const CanceledBookerPage = Loadable(lazy(() => import("../pages/public/Booker/CanceledBooker")));

const AIPhoneBookerPage = Loadable(lazy(() => import("../pages/public/PhoneBooker/ai-phone-booker")));

const PublicRoutes = {
  children: [
    {
      path: "/voice-call/:id",
      element: <VoiceCallPage />,
    },
    {
      path: "/job/:id",
      element: <ApplicationPage />,
    },
    {
      path: "/company-login",
      element: <CompanyRedirectPage />,
    },
    {
      path: "/candidate-login",
      element: <CandidateRedirectPage />,
    },
    {
      path: "/auma-booker",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <AumaBookerPage />,
        },
        {
          path: "upcoming",
          element: <UpcomingBookerPage />,
        },
        {
          path: "canceled",
          element: <CanceledBookerPage />,
        },
      ],
    },
    {
      path: "/ai-phone-booker",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <AIPhoneBookerPage />,
        },
      ],
    },
  ],
};

export default PublicRoutes;

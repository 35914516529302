// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPopper-root {
}

.MuiPopper-root .MuiTooltip-tooltip {
  border-radius: 8px;
  border: 1px solid #e2e4e9;
  background: #f6f8fa;
  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
  margin-bottom: 0 !important;
  padding: 8px;

  color: #0a0d14;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  min-width: 300px;
  min-height: 100px;
}

.onboarding-checklist-tooltip.MuiTooltip-tooltip {
  background-color: white;
  color: #000;
  border: 0;
  border-radius: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0px;
  position: relative;
}

.onboarding-checklist-tooltip.MuiTooltip-tooltip::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -0.5px;
  right: -1px;
  bottom: -1px;
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(90deg, #61c0ff 0%, #ff98c9 40%, #63baba 100%);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/tooltip.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,qDAAqD;EACrD,2BAA2B;EAC3B,YAAY;;EAEZ,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;;EAEjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,SAAS;EACT,mBAAmB;EACnB,2CAA2C;EAC3C,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,yEAAyE;AAC3E","sourcesContent":[".MuiPopper-root {\n}\n\n.MuiPopper-root .MuiTooltip-tooltip {\n  border-radius: 8px;\n  border: 1px solid #e2e4e9;\n  background: #f6f8fa;\n  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);\n  margin-bottom: 0 !important;\n  padding: 8px;\n\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n\n  min-width: 300px;\n  min-height: 100px;\n}\n\n.onboarding-checklist-tooltip.MuiTooltip-tooltip {\n  background-color: white;\n  color: #000;\n  border: 0;\n  border-radius: 24px;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n  padding: 0px;\n  position: relative;\n}\n\n.onboarding-checklist-tooltip.MuiTooltip-tooltip::before {\n  content: \"\";\n  position: absolute;\n  top: -1px;\n  left: -0.5px;\n  right: -1px;\n  bottom: -1px;\n  z-index: -1;\n  border-radius: inherit;\n  background: linear-gradient(90deg, #61c0ff 0%, #ff98c9 40%, #63baba 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
